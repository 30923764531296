








































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { IDoctorSchedule } from "../../models/DoctorSchedule";
import { IDoctorChamberSchedule } from "../../models/DoctorChamberSchedule";
import DoctorScheduleService from "../../service/DoctorScheduleService";
import DoctorChamberScheduleService from "../../service/DoctorChamberScheduleService";
import DoctorChamberService from "../../service/DoctorChamberService";
import { DayOfWeek } from "../../../helper/Enums";
import { IDoctorChamber } from "../../models/DoctorChamber";
import AlertService from "../../../common-app/service/AlertService";

// @Component({
//   filters: {
//     filterName(value: number, list: IDoctorChamberSchedule[], chamberId: number, day: number) {
//       let isTrue = list.some((s) => s.doctorScheduleId == value && s.chamberId == chamberId && s.day == day && s.isActive);
//       return isTrue ? "btn-primary" : "btn-danger";
//     },
//   },
// })

@Component({
  filters: {
    filterName(value: number, list: IDoctorSchedule[], chamberId: number, day: any) {
      let isTrue = list.some((s) => s.isActive == true && s.chamberId == chamberId && s.apptDay == day);
      return isTrue ? "btn-primary" : "btn-danger";
    },
  },
})
export default class Chamber extends Vue {
  public scheduleList: IDoctorSchedule[] = [];
  public chamberScheduleList: IDoctorChamberSchedule[] = [];
  public currentChamberId: number = 0;
  public startTime: any = "";
  public endTime: any = "";
  public submitted: boolean = false;
  public days: DayOfWeek = DayOfWeek.Sunday;
  public DayOfWeek = DayOfWeek;
  public chamberName = "";
  public chamberList: IDoctorChamber[] = [];
  public isShow: Boolean = false;
  public checkedDays: any = [];
  public checkedTime: any = [];
  public chambers: IDoctorChamber[] = [];
  public avgConsultTime: number = 0;

  public errorTimeMessage: string = "";
  public errorDayMessage: string = "";
  public isSubmitted: boolean = false;

  created() {
    this.currentChamberId = Number(this.$route.params.id) || 0;
    this.chamberList = this.$store.getters.chamberList || [];
    //this.chamberName = this.chamberList.find((s) => s.id == this.currentChamberId)!.name || "";
    //console.log(this.chamberList);

    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        this.chamberList = res.data.result;
        //console.log(this.chamberList);
        this.chamberName = this.chamberList.find((s) => s.id == this.currentChamberId)!.name || "";
        this.avgConsultTime = this.chamberList.find((s) => s.id == this.currentChamberId)!.avgConsultTime || 0;
        //console.log(this.avgConsultTime);
        if(this.avgConsultTime>0){
        this.checkedTime.push(this.avgConsultTime);
        }

      }
    });

    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result || [];
        //this.$store.dispatch("addChamberList", res.data.result);
        // console.log(this.chambers);
      }
    });

    DoctorScheduleService.GetListByUser(this.currentChamberId)
      .then((res) => {
        this.scheduleList = res.data.result || [];
      })
      .catch((e) => alert(e));
    DoctorChamberScheduleService.GetScheduleByUser().then((res) => {
      this.chamberScheduleList = res.data.result || [];
    });

    this.scheduleList.filter((f) => f.chamberId == this.currentChamberId);

    this.$validator.extend("less", {
      getMessage(field, val) {
        return `${field} Must be less than End Time`;
      },
      validate(value, compare: any) {
        let startT = (compare.compare as any) || 0;
        return Number(value.id) < Number(startT);
      },
      paramNames: ["compare"],
    });

    this.$validator.extend("grater", {
      getMessage(field, val) {
        return `${field} Must be grater than Start Time`;
      },
      validate(value, compare: any) {
        let startT = (compare.compare as any) || 0;
        return Number(value.id) > Number(startT);
      },
      paramNames: ["compare"],
    });
  }

  getChamberList(chamberId: number) {
    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        this.chamberList = res.data.result;
        // console.log(this.chamberList);
        this.avgConsultTime = this.chamberList.find((s) => s.id == chamberId)!.avgConsultTime || 0;
        //console.log(this.avgConsultTime);
      }
    });
  }

  @Watch("$route")
  add(newRoute: any, oldRoute: any) {
    this.currentChamberId = Number(newRoute.params.id) || 0;
    //this.chamberName = this.chamberList.find((s) => s.id == this.currentChamberId)!.name || "";
    // DoctorScheduleService.GetListByChamber(this.currentChamberId)
    //   .then(res => (this.scheduleList = res.data.result || []))
    //   .catch(e => console.log(e));
    window.location.reload();
  }

  // submitFrom() {
  //   this.$validator.validateAll().then(valid => {
  //     if (valid) {
  //       debugger;
  //       let data = {
  //         startTime: this.startTime.value,
  //         endTime: this.endTime.value,
  //         sTimeId: Number(this.startTime.id),
  //         eTimeId: Number(this.endTime.id)
  //       } as IDoctorSchedule;
  //       let list = this.scheduleList;
  //       let isExist = this.scheduleList.find(
  //         s => s.sTimeId == data.sTimeId && s.eTimeId == data.eTimeId
  //       );
  //       if (isExist) {
  //         alert("this time slot already added, try another!");
  //         return;
  //       }

  //       // let isExist3 = this.scheduleList.find(
  //       //   s => s.sTimeId < data.sTimeId || s.sTimeId < data.eTimeId
  //       // );
  //       let isExist3 = this.scheduleList.find(
  //         s =>
  //           (s.sTimeId < data.sTimeId || s.sTimeId > data.sTimeId || s.sTimeId < data.eTimeId) &&
  //           (s.eTimeId < data.sTimeId || s.eTimeId < data.eTimeId || s.eTimeId > data.eTimeId)
  //       );

  //       let isExisttt = this.scheduleList.find(
  //         s =>
  //           (s.sTimeId > data.sTimeId && s.eTimeId > data.sTimeId) &&
  //           (s.eTimeId < data.sTimeId && s.eTimeId < data.eTimeId)
  //       );

  //       let isExist2 = this.scheduleList.find(
  //         s =>
  //           s.sTimeId > data.sTimeId ||
  //           s.eTimeId > data.eTimeId ||
  //           s.eTimeId > data.sTimeId
  //       );
  //       if (isExist2 && isExist3) {
  //         alert("this time slot Overlap, try another!");
  //         return;
  //       }

  //       this.submitted = true;
  //       DoctorScheduleService.insert(data)
  //         .then(res => {
  //           this.scheduleList.push(res.data.result || []);
  //           this.submitted = false;
  //           this.$validator.errors.clear();
  //           alert("success");
  //         })
  //         .catch(re => {
  //           this.submitted = false;
  //         });
  //     }
  //   });
  // }

  submitFrom() {
    this.isSubmitted = true;
    //alert(this.checkedTime);
    // console.log(this.checkedDays.toString());
    // console.log(this.checkedTime.toString());
    // if (this.checkedTime.length != 1) {
    //   // this.errorTimeMessage="Please select one average time"
    //   //AlertService.sendMessage({ status: false, message: "Please select One Average time to consult a patient" });
    //   //return;
    // } else {
    //   this.errorTimeMessage = "";
    // }
    // if (this.checkedTime.length == 1) {
    //   AlertService.sendMessage({
    //     status: false,
    //     message: "This time can not be changed. To change this time slot, you may need to contact with BDDOT support.",
    //   });
    // }
    this.$validator.validateAll().then((valid) => {
      if (valid) {
        // debugger;
        let data = {
          // startTime: this.startTime.value,
          // endTime: this.endTime.value,
          sTimeId: Number(this.startTime.id),
          eTimeId: Number(this.endTime.id),
          avgTime: this.checkedTime.toString(),
          //days:Array(this.checkedDays),
          days: this.checkedDays.join(","),
          //chamberId: Number(this.$route.params.id),
          chamberId: this.currentChamberId,
        } as IDoctorSchedule;
        let list = this.scheduleList;
 
        //Time overlapping validation start

        //alert('data start: ' + data.sTimeId + ' - data end: ' + data.eTimeId);

        /*
        var isExist = false;
        var isExist2 = false;
        var isExist3 = false;
        for (var j = 0; j < this.checkedTime.length; j++) {
          for (var i = 0; i < this.scheduleList.length; i++) {
            //alert(this.checkedDays[j]);
            //alert('schedule start: ' + this.scheduleList[i].sTimeId + ' - end: ' + this.scheduleList[i].eTimeId);
            if (
              this.scheduleList[i].sTimeId <= data.sTimeId &&
              this.scheduleList[i].eTimeId > data.sTimeId &&
              this.scheduleList[i].apptDay == this.checkedDays[j]
            ) {
              isExist = true;
              break;
            }
            if (
              this.scheduleList[i].sTimeId < data.eTimeId &&
              this.scheduleList[i].eTimeId >= data.eTimeId &&
              this.scheduleList[i].apptDay == this.checkedDays[j]
            ) {
              isExist2 = true;
              break;
            }
            if (
              (data.sTimeId <= this.scheduleList[i].sTimeId &&
                data.eTimeId > this.scheduleList[i].sTimeId &&
                this.scheduleList[i].apptDay == this.checkedDays[j]) ||
              (data.sTimeId < this.scheduleList[i].eTimeId &&
                data.eTimeId >= this.scheduleList[i].eTimeId &&
                this.scheduleList[i].apptDay == this.checkedDays[j])
            ) {
              isExist3 = true;
              break;
            }
          }
        }
        if (isExist) {
          alert("start time invalid, try another!");
          return;
        }
        if (isExist2) {
          alert("end time invalid, try another!");
          return;
        }
        if (isExist3) {
          alert("time slot Overlap, try another!");
          return;
        }
        */
        //Time overlapping validation start

        this.submitted = true;
        DoctorScheduleService.insert(data)
          .then((res) => {
            // this.scheduleList.push(res.data.result || []);
            // this.scheduleList = res.data.result || [];
            // this.submitted = false;
            // this.$validator.errors.clear();
            // alert("success");
            //  this.isShow = false;

            if (res.data.status) {
              this.scheduleList = res.data.result || [];
              this.submitted = false;
              this.isSubmitted = false;
              this.$validator.errors.clear();
              this.isShow = false;
              //AlertService.sendMessage("success");
              AlertService.sendMessage({
                status: true,
                message:
                  "Your appointment setting successfully completed . Your new appointment slot will be available for patient within  next 24 hours . ",
              });
              this.checkedDays = [];
              this.checkedTime = [];
              this.getChamberList(this.currentChamberId);
            } else {
              this.scheduleList = res.data.result || [];
              this.submitted = false;
              this.$validator.errors.clear();
              this.isShow = false;
              // alert("Time Slot is not exists");
              // AlertService.sendMessage({ status: false, message: "Duplicate Time Slot" });
              AlertService.sendMessage({ status: false, message: "Duplicate Time Slot" });
            }
          })
          .catch((re) => {
            this.submitted = false;
          });
      }
    });
  }

  // addScheduleToChamber(day: number, schedule: IDoctorSchedule) {
  //   //console.log(schedule);
  //   let currentScheduleActive = this.chamberScheduleList.find(
  //     (s) => s.doctorScheduleId == schedule.id && s.day == day && s.isActive && s.chamberId == this.currentChamberId
  //   );
  //   if (currentScheduleActive) {
  //     alert("already active");
  //     if (confirm("Do you want to de-active this schedule?")) {
  //       DoctorChamberScheduleService.deActiveSchedule(currentScheduleActive!.id).then((res) => {
  //         if (res.data.status) {
  //           let index = this.chamberScheduleList.findIndex((s) => s.id == currentScheduleActive!.id);
  //           this.chamberScheduleList.splice(index, 1);
  //           alert("de-active success");
  //         } else {
  //           alert("faild");
  //         }
  //       });
  //     }
  //     return;
  //   }

  //   let currentSchedule = this.chamberScheduleList.find((s) => s.doctorScheduleId == schedule.id && s.day == day);

  //   if (currentSchedule) {
  //     let chambers: IDoctorChamber[] = this.$store.getters.chamberList;
  //     let data = chambers.find((s) => s.id == currentSchedule!.chamberId);
  //     if (data) alert("this time slot already added " + data.name);
  //   } else {
  //     let doctorSchedule = {
  //       chamberId: this.currentChamberId,
  //       day,
  //       isActive: true,
  //       doctorScheduleId: schedule.id,
  //       startTime: schedule.startTime,
  //       endTime: schedule.endTime,
  //     } as IDoctorChamberSchedule;

  //     DoctorChamberScheduleService.insert(doctorSchedule).then((res) => {
  //       this.chamberScheduleList.push({ ...res.data.result });
  //       let index = this.scheduleList.findIndex((s) => s.id == schedule.id);
  //       this.scheduleList[index] = Object.assign(schedule, {
  //         chamberId: this.currentChamberId,
  //         isActive: !schedule.isActive,
  //         day: day,
  //       });
  //       alert("add success");
  //       // DoctorScheduleService.GetListByChamber()
  //       //   .then(res => (this.scheduleList = res.data.result || []))
  //       //   .catch(e => console.log(e));
  //       // alert("add success");
  //     });
  //   }
  // }

  addScheduleToChamber(day: number, schedule: IDoctorSchedule) {
    let currentSchedule = this.chamberScheduleList.find((s) => s.doctorScheduleId == schedule.id && s.day == day);

    let doctorSchedule = {
      chamberId: this.currentChamberId,
      day,
      isActive: true,
      doctorScheduleId: schedule.id,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
    } as IDoctorChamberSchedule;

    if (schedule.isActive) {
      this.$bvModal
        .msgBoxConfirm("Do you want to de-active this schedule?", {
          title: "Please Confirm",
          okVariant: "success",
          okTitle: "NO",
          cancelVariant: "danger",
          cancelTitle: "YES",
          hideHeaderClose: true,
          headerClass: "justify-content-center",
          bodyClass: "text-center",
          centered: true,
          footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
        })
        .then((value) => {
          if (value == false) {
            schedule.isActive = false;
            DoctorScheduleService.UpdateSchedule(schedule).then((res) => {
              if (res.data.status) {
                this.scheduleList = res.data.result || [];
                AlertService.sendMessage({ status: true, message: "Updated Successfully" });
              } else {
                //alert(res.data.message);
                AlertService.sendMessage({ status: false, message: res.data.message });
              }
            });
          }
        });
    } else {
      schedule.isActive = true;
      DoctorScheduleService.UpdateSchedule(schedule).then((res) => {
        this.scheduleList = res.data.result || [];
        if (res.data.status) {
          AlertService.sendMessage({ status: true, message: "Updated Successfully" });
        } else {
          schedule.isActive = false;
          AlertService.sendMessage({ status: false, message: res.data.message });
        }
      });
    }
  }

  changeEnd(v: any) {
    // console.log(v);
    // console.log(this.endTime);
  }

  onChange(event: any) {
    this.currentChamberId = event.target.value;
    this.onChangeSchedule();
  }

  onChangeSchedule() {
    DoctorScheduleService.GetListByUser(this.currentChamberId)
      .then((res) => {
        this.scheduleList = res.data.result || [];
      })
      .catch((e) => alert(e));
    DoctorChamberScheduleService.GetScheduleByUser().then((res) => {
      this.chamberScheduleList = res.data.result || [];
    });
    this.chamberName = this.chamberList.find((s) => s.id == this.currentChamberId)!.name || "";
    this.avgConsultTime = this.chamberList.find((s) => s.id == this.currentChamberId)!.avgConsultTime || 0;
    this.scheduleList.filter((f) => f.chamberId == this.currentChamberId);
  }

  removeSlot(scheduleId: number, index: number) {
    if (confirm("Do you really want to delete?")) {
      DoctorScheduleService.getDelete(scheduleId).then((res) => {
        if (res.data.status) {
          this.scheduleList.splice(index, 1);
          AlertService.sendMessage({ status: true, message: "delete success" });
        } else {
          AlertService.sendMessage("delete failed");
        }
      });
    }
  }

  public get TimeFormat(): any[] {
    var times: Array<any> = [];
    var quarterHours = ["00", "30"];
    var id = 1;
    for (var i = 8; i < 24; i++) {
      for (var j = 0; j < 2; j++) {
        let format = i >= 12 ? "PM" : "AM";
        let hors = i > 12 ? i - 12 : i;
        let data = {
          id: i + "" + quarterHours[j],
          time24: "0" + i,
          text: ("0" + hors).slice(-2) + ":" + quarterHours[j] + " " + format,
          hour: ("0" + hors).slice(-2),
          minute: quarterHours[j],
          value: ("0" + i).slice(-2) + ":" + quarterHours[j] + ":00",
        } as any;
        times.push(data);
        id++;
      }
    }
    return times;
  }

  close() {
    this.isShow = false;
  }
}
