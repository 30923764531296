import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorChamberSchedule } from "../models/DoctorChamberSchedule";


export default {
  insert(chamberSchudle: IDoctorChamberSchedule) {
    return AuthAxios.post(
      ApiURIs.DrChamberScheduleInsert,
      JSON.stringify(chamberSchudle)
    );
  },
  GetScheduleByUser() {
    return AuthAxios.get(ApiURIs.DrChamberScheduleByUser);
  },
  deActiveSchedule(scheduleId: number) {
    return AuthAxios.get(`${ApiURIs.DrChamberScheduleDeActive}/${scheduleId}`);
  }
};
